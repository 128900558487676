<template>
  <div class="CustomFormItem">

    <template v-if="item.type == 'grid'">
      <el-row
        type="flex"
        :gutter="item.options.gutter || 0"
        :align="item.options.align"
        :justify="item.options.justify"
      >
        <el-col
          v-for="(columnItem, columnIndex) in item.columns"
          :key="columnIndex"
          :span="columnItem.span || 0"
        >
          <div
            v-for="(item, index) in columnItem.list"
            :key="index"
          >
            <custom-form-item
              :models="models"
              :item="item"
            />
          </div>
        </el-col>
      </el-row>
    </template>

    <template v-else>
      <el-form-item
        v-if="item.type != 'grid'"
        :prop="item.model"
        :label="item.name"
        :rules="rules"
        :label-width="item.options.isLabelWidth ? item.options.labelWidth + 'px' : ''"
      >
        <div class="custom-form-item-container" :class="{ 'custom-width': item.options.width != '' && item.options.width != undefined }" :style="{ 'width': item.options.width }">

          <template v-if="['input'].includes(item.type)">
            <v-input v-if="item.options.dataType == 'number'"
              v-model="model"
              type="string"
              ref="myInput"
              @blur="blurEnd()"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              :v-on:input="inputCheckNumber(item.options.pointPosition)"
            ></v-input>
            <!-- <v-input
            @input="$emit('input', $event.target.value)"
              v-if="['number', 'integer', 'float'].includes(item.options.dataType)"
              v-model.number="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              :maxlength="item.options.length"
            /> -->
            <v-input
              v-else
              v-model="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              :maxlength="item.options.length"
            />
          </template>

          <template v-else-if="['textarea'].includes(item.type)">
            <v-textarea
              v-model="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              :maxlength="item.options.length"
            />
          </template>

          <template v-else-if="['number'].includes(item.type)">
            <v-input-number
              v-model="model"
              :min="item.options.min"
              :max="item.options.max"
              :step="item.options.step"
              :disabled="item.options.disabled"
              controls
            />
          </template>

          <template v-else-if="['radio'].includes(item.type)">
            <checkbox-plus
              type="radio"
              :value.sync="model"
              :options="item.options.options"
              :align="item.options.inline ? 'horizontal' : 'vertical'"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['checkbox'].includes(item.type)">
            <checkbox-plus
              type="default"
              mode="checkbox-plus"
              :value.sync="model"
              :options="item.options.options"
              :align="item.options.inline ? 'horizontal' : 'vertical'"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['time'].includes(item.type)">
            <v-timepicker
              v-model="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['date'].includes(item.type)">
            <v-datepicker
              v-model="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              :type="item.options.type"
            />
          </template>

          <template v-else-if="['rate'].includes(item.type)">
            <rate
              v-model="model"
              :max="item.options.max"
              :allow-half="item.options.allowHalf"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['select'].includes(item.type)">
            <v-select
              v-model="model"
              :options="item.options.options"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['switch'].includes(item.type)">
            <el-switch
              v-model="model"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['text'].includes(item.type)">
            <span
              v-text="item.options.defaultValue"
            />
          </template>

          <template v-else-if="['imgupload'].includes(item.type)">
            <v-uploader
              :imgUrls.sync="model"
              :action="uploadURL"
              :limit="item.options.length"
              :fileWidth="item.options.size.width"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['fileupload'].includes(item.type)">
            <v-uploader
              :fileList.sync="model"
              :action="fileUploadURL"
              :fileSize="5"
              :onlyImage="false"
              :limit="item.options.limit"
              :fileWidth="item.options.size.width"
              :disabled="item.options.disabled"
            />
          </template>

          <template v-else-if="['org'].includes(item.type)">
            <v-select2
              v-model="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              v-bind="orgParams"
              @onChange="orgChange"
            />
          </template>

          <template v-else-if="['community'].includes(item.type)">
            <v-select2
              v-model="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              v-bind="communityParams"
              @onChange="communityChange"
            />
          </template>

          <template v-else-if="['communityroom'].includes(item.type)">
            <v-select2
              v-model="model"
              :placeholder="item.options.placeholder"
              :disabled="item.options.disabled"
              v-bind="communityroomParams"
              @onChange="communityRoomChange"
            />
          </template>

          <template v-else-if="['area'].includes(item.type)">
            <area-select
            :disabled="item.options.disabled"
            :placeholder="item.options.placeholder"
            :clearable="item.options.clearable"
            :filterable="item.options.showinsearch"
            :detail-addr="false"
            :type="item.options.type"
            :codes.sync="model"
            @change="selectedAreaChange"
            >
            </area-select>
          </template>

          <template v-else-if="['username', 'userphone', 'useremail', 'userorg', 'userorgaddress'].includes(item.type)">
            <v-input
              v-model="model"
              :placeholder="item.options.placeholder"
              :maxlength="item.options.length"
            />
          </template>

          <template v-else-if="['usersex'].includes(item.type)">
            <checkbox-plus
              type="radio"
              :value.sync="model"
              :options="item.options.options"
              :align="item.options.inline ? 'horizontal' : 'vertical'"
            />
          </template>

          <template v-else-if="['userage'].includes(item.type)">
            <v-input-number
              v-model="model"
              controls
            />
          </template>

          <template v-else-if="['table'].includes(item.type)">
            <table-panel
              :headers="tableHeaderMap(item)"
              :tableData="model"
              maxHeight="500"
              operateColumnWidth="100">
              <template #operateSlot="scope">
                <v-button text="编辑" type="text" @click="editRowMethod(scope.row)"></v-button>
                <v-button text="删除" type="text" @click="deleteRowMethod(scope.row)"></v-button>
              </template>
            </table-panel>
            <el-button icon="el-icon-plus" type="text" @click="addRowMethod">添加</el-button>
          </template>
        </div>
      </el-form-item>
    </template>

  </div>
</template>

<script>
import { uploadURL } from 'common/api'
import { vUploader } from 'components/control'
import { CheckboxPlus, AreaSelect, TablePanel } from 'components/bussiness'
import FmFormTable from './FormTable'
import { Rate, Switch, Table } from 'element-ui'

export default {
  name: 'CustomFormItem',
  components: {
    vUploader,
    AreaSelect,
    CheckboxPlus,
    Rate,
    FmFormTable,
    TablePanel,
    'el-switch': Switch,
  },
  props: ['models', 'item', 'formName', 'formConfig'],
  data () {
    return {
      model: '',
      rules: [],
      uploadURL: `${uploadURL}?module=user`,
      fileUploadURL: `${uploadURL}?module=show`,
      provinceOps: [],
      cityOps: [],
      areaOps: [],
      province: '',
      city: '',
      area: '',
      selectedChangeValue: '',
      tableHeaders: {},
      orgParams: {
        searchUrl: `${API_CONFIG.controlBaseURL}${this.item.options.searchUrl}`,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName'
        }
      },
      communityParams: {
        searchUrl: `${API_CONFIG.controlBaseURL}${this.item.options.searchUrl}`,
        request: {
          text: 'communityName',
          value: 'id'
        },
        response: {
          text: 'name'
        }
      },
      communityroomParams: {
        searchUrl: `${API_CONFIG.controlBaseURL}${this.item.options.searchUrl}`,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'name'
        }
      }
    }
  },
  methods: {
    // 处理子表的header
    handleTableHeaderData (tableItem) {
      const listFields = tableItem.tableColumns
      if (Array.isArray(listFields) && listFields.length) {
        const headers = []
        listFields.forEach(item => {
          if (item.options.showinlist) {
            if (['select', 'checkbox', 'radio', 'usersex', 'switch', 'org', 'community', 'communityroom', 'area'].includes(item.type)) {
              headers.push({
                prop: item.model + '_display',
                label: item.name
              })
            } else {
              headers.push({
                prop: item.model,
                label: item.name
              })
            }
          }
        })
        this.tableHeaders[tableItem.model] = headers
      }
    },
    // 子表表头数据
    tableHeaderMap (item) {
      return this.tableHeaders[item.model]
    },
    // 新增子表单记录
    addRowMethod () {
      this.$router.push({
        name: 'customFormFormSubmit',
        query: {
          formName: this.formName,
          formConfig: this.formConfig,
          tableList: this.model,
          item: this.item
        }
      })
    },
    // 编辑子表单单条记录
    editRowMethod (rowItem) {
      var index = -1
      if (this.model.indexOf(rowItem) > -1) {
        index = this.model.indexOf(rowItem)
      }
      this.$router.push({
        name: 'customFormFormSubmit',
        query: {
          formName: this.formName,
          formConfig: this.formConfig,
          tableList: this.model,
          item: this.item,
          model: rowItem,
          tableIndex: index
        }
      })
    },
    // 删除子表
    async deleteRowMethod (rowItem) {
      let message = '确定要删除吗?'
      let isOk = await this.$confirm(message)
      if (isOk) {
        if (this.model.indexOf(rowItem) > -1) {
          var i = this.model.indexOf(rowItem)
          this.model.splice(i, 1)
        }
      }
    },
    blurEnd () {
      // 焦点失去时触发，小数的自动补全小数位
      const pointPosition = this.item.options.pointPosition
      if (this.item.type === 'input' && this.model.toString().length > 0 && pointPosition > 0) {
        this.model = parseFloat(this.model).toFixed(pointPosition)
      }
    },
    inputCheckNumber (pointPosition) {
      // 有小数位
      if (this.model === undefined) {
        this.model = ''
      }
      if (pointPosition > 0) {
        var license_num = '' + this.model.toString()
        var str = `^(\\-)*(\\d+)\\.(\\d{${pointPosition}}).*$`
        license_num = license_num
          .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
          .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(new RegExp(str), '$1$2.$3')
        if (license_num.indexOf('.') < 0 && license_num != '') {
          // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          license_num = parseFloat(license_num)
        }
        this.model = license_num
      } else {
        // 没有小数位，表示输入整数
        var license_num = this.model.toString()
        license_num = license_num.replace(/[^\d]/g, '') // 清除“数字”和“.”以外的字符
        if (license_num.indexOf('.') < 0 && license_num != '') {
          // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          license_num = parseInt(license_num)
        }
        this.model = license_num
      }
      // 强制更新下输入框数据
      if (this.$refs.myInput) {
        this.$refs.myInput.model = license_num
      }
    },
    // 省市区变化
    selectedAreaChange (selectedArea) {
      if (selectedArea) {
        let areaStr = ''
        if (selectedArea.province) {
          this.province = selectedArea.province.name
          areaStr = selectedArea.province.name
        }
        if (selectedArea.city) {
          this.city = selectedArea.city.name
          areaStr = `${areaStr} - ${selectedArea.city.name}`
        }
        if (selectedArea.area) {
          this.area = selectedArea.area.name
          areaStr = `${areaStr} - ${selectedArea.area.name}`
        }
        this.selectedChangeValue = areaStr
      }
    },
    // 组织变化
    orgChange (option) {
      this.selectedChangeValue = option.orgName
    },
    // 项目变化
    communityChange (option) {
      this.selectedChangeValue = option.name
    },
    // 房号变化
    communityRoomChange (option) {
      this.selectedChangeValue = option.name
    }
  },
  created () {
    this.model = this.models[this.item.model]
    const item = this.item
    const type = item.type
    const dataType = item.options.dataType
    const required = item.options.required
    if (type === 'table') {
      this.handleTableHeaderData(this.item)
    }
    if (dataType) {
      if (dataType !== 'number') {
        this.rules.push({
          type: dataType,
          message: `${item.name}格式不正确`
        })
      }
    }
    if (required) {
      let message = ''
      let rule = {
        required: true,
        trigger: 'change'
      }
      if (['input', 'textarea', 'number', 'username', 'userphone', 'userage', 'useremail', 'userorg', 'userorgaddress'].includes(type)) {
        message = '请输入' + item.name
      } else if (['checkbox'].includes(type)) {
        message = '请输入' + item.name
        // TODO 暂时解决自定义表单嵌套checkbox-plus的value改变之前就触发校验的问题---权宜之计
        const _this = this
        rule.validator = (rule, value, callback) => {
          if (!_this.model.length) {
            callback(new Error(message))
          } else {
            callback()
          }
        }
      } else {
        message = '请选择' + item.name
      }
      rule.message = message
      this.rules.push(rule)
    }
  },
  watch: {
    model: {
      deep: true,
      handler (val) {
        this.models[this.item.model] = val
        this.$emit('update:models', {
          ...this.models,
          [this.item.model]: val
        })
      }
    },
    models: {
      deep: true,
      handler (val) {
        this.model = val[this.item.model]
      }
    }
  }
}
</script>
