<template>
  <div class="form-table" :class="{'is-disabled': disabled}">
    <el-table
      :data="tableData"
      max-height="600"
      border
    >
      <el-table-column v-if="columns.length==0"></el-table-column>
      <template v-else>
        <el-table-column
          v-for="column in columns"
          :key="column.key"
          :label="column.name">
          <template slot-scope="scope">
            <div class="vnode-wrapper">
              <span >{{scope.row[column.model]}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleRemove(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-button icon="el-icon-plus" type="text" @click="handleAddRow">添加</el-button>
  </div>
</template>

<script>

import { TablePanel } from 'components/bussiness'

export default {
  components: {
    CustomFormItem: () => import('../CustomFormItem.vue'),
    TablePanel
  },
  props: {
    columns: Array,
    models: Array,
    disabled: Boolean,
    // 新增行方法
    handleAddRowMethod: {
      type: Function,
      default: null
    },
    // 编辑方法
    handleEditRowMethod: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      tableData: this.models
    }
  },
  methods: {
    handleAddRow () {
      if (this.handleAddRowMethod) {
        this.handleAddRowMethod()
      }
    },
    handleRemove (index) {
      this.tableData.splice(index, 1)
    },
    handleEdit (index) {
      if (this.handleEditRowMethod) {
        this.handleEditRowMethod(index)
      }
    }
  },
  watch: {
    dataModels: {
      deep: true,
      handler (val) {
        this.$emit('update:models', val)
      }
    },
    models: {
      deep: true,
      handler (val) {
        this.dataModels = val
      }
    },
  }
}
</script>

<style lang="scss">
.form-table {
  position: relative;
  width: 100%;
  height: 100%;
  .vnode-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.is-disabled {
    background-color: #F5F7FA;
    &::after{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      cursor: no-drop;
    }
  }

  .el-table__empty-block {
    display: none;
  }

  .scope-action {
    display: none;
  }

  .scope-index {
    display: block;
  }

  .hover-row {
    .scope-action {
      display: block;
      .el-button {
        padding: 0px;
      }
    }
    .scope-index {
      display: none;
    }
  }
}
</style>
