var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-table", class: { "is-disabled": _vm.disabled } },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, "max-height": "600", border: "" } },
        [
          _vm.columns.length == 0
            ? _c("el-table-column")
            : [
                _vm._l(_vm.columns, function (column) {
                  return _c("el-table-column", {
                    key: column.key,
                    attrs: { label: column.name },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "vnode-wrapper" }, [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row[column.model])),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "150", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope.$index, scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemove(
                                    scope.$index,
                                    scope.row
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
        ],
        2
      ),
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-plus", type: "text" },
          on: { click: _vm.handleAddRow },
        },
        [_vm._v("添加")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }