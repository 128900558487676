var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormItem" },
    [
      _vm.item.type == "grid"
        ? [
            _c(
              "el-row",
              {
                attrs: {
                  type: "flex",
                  gutter: _vm.item.options.gutter || 0,
                  align: _vm.item.options.align,
                  justify: _vm.item.options.justify,
                },
              },
              _vm._l(_vm.item.columns, function (columnItem, columnIndex) {
                return _c(
                  "el-col",
                  { key: columnIndex, attrs: { span: columnItem.span || 0 } },
                  _vm._l(columnItem.list, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("custom-form-item", {
                          attrs: { models: _vm.models, item: item },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              }),
              1
            ),
          ]
        : [
            _vm.item.type != "grid"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: _vm.item.model,
                      label: _vm.item.name,
                      rules: _vm.rules,
                      "label-width": _vm.item.options.isLabelWidth
                        ? _vm.item.options.labelWidth + "px"
                        : "",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "custom-form-item-container",
                        class: {
                          "custom-width":
                            _vm.item.options.width != "" &&
                            _vm.item.options.width != undefined,
                        },
                        style: { width: _vm.item.options.width },
                      },
                      [
                        ["input"].includes(_vm.item.type)
                          ? [
                              _vm.item.options.dataType == "number"
                                ? _c("v-input", {
                                    ref: "myInput",
                                    attrs: {
                                      type: "string",
                                      placeholder: _vm.item.options.placeholder,
                                      disabled: _vm.item.options.disabled,
                                      "v-on:input": _vm.inputCheckNumber(
                                        _vm.item.options.pointPosition
                                      ),
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.blurEnd()
                                      },
                                    },
                                    model: {
                                      value: _vm.model,
                                      callback: function ($$v) {
                                        _vm.model = $$v
                                      },
                                      expression: "model",
                                    },
                                  })
                                : _c("v-input", {
                                    attrs: {
                                      placeholder: _vm.item.options.placeholder,
                                      disabled: _vm.item.options.disabled,
                                      maxlength: _vm.item.options.length,
                                    },
                                    model: {
                                      value: _vm.model,
                                      callback: function ($$v) {
                                        _vm.model = $$v
                                      },
                                      expression: "model",
                                    },
                                  }),
                            ]
                          : ["textarea"].includes(_vm.item.type)
                          ? [
                              _c("v-textarea", {
                                attrs: {
                                  placeholder: _vm.item.options.placeholder,
                                  disabled: _vm.item.options.disabled,
                                  maxlength: _vm.item.options.length,
                                },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["number"].includes(_vm.item.type)
                          ? [
                              _c("v-input-number", {
                                attrs: {
                                  min: _vm.item.options.min,
                                  max: _vm.item.options.max,
                                  step: _vm.item.options.step,
                                  disabled: _vm.item.options.disabled,
                                  controls: "",
                                },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["radio"].includes(_vm.item.type)
                          ? [
                              _c("checkbox-plus", {
                                attrs: {
                                  type: "radio",
                                  value: _vm.model,
                                  options: _vm.item.options.options,
                                  align: _vm.item.options.inline
                                    ? "horizontal"
                                    : "vertical",
                                  disabled: _vm.item.options.disabled,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.model = $event
                                  },
                                },
                              }),
                            ]
                          : ["checkbox"].includes(_vm.item.type)
                          ? [
                              _c("checkbox-plus", {
                                attrs: {
                                  type: "default",
                                  mode: "checkbox-plus",
                                  value: _vm.model,
                                  options: _vm.item.options.options,
                                  align: _vm.item.options.inline
                                    ? "horizontal"
                                    : "vertical",
                                  disabled: _vm.item.options.disabled,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.model = $event
                                  },
                                },
                              }),
                            ]
                          : ["time"].includes(_vm.item.type)
                          ? [
                              _c("v-timepicker", {
                                attrs: {
                                  placeholder: _vm.item.options.placeholder,
                                  disabled: _vm.item.options.disabled,
                                },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["date"].includes(_vm.item.type)
                          ? [
                              _c("v-datepicker", {
                                attrs: {
                                  placeholder: _vm.item.options.placeholder,
                                  disabled: _vm.item.options.disabled,
                                  type: _vm.item.options.type,
                                },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["rate"].includes(_vm.item.type)
                          ? [
                              _c("rate", {
                                attrs: {
                                  max: _vm.item.options.max,
                                  "allow-half": _vm.item.options.allowHalf,
                                  disabled: _vm.item.options.disabled,
                                },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["select"].includes(_vm.item.type)
                          ? [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.item.options.options,
                                  placeholder: _vm.item.options.placeholder,
                                  disabled: _vm.item.options.disabled,
                                },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["switch"].includes(_vm.item.type)
                          ? [
                              _c("el-switch", {
                                attrs: { disabled: _vm.item.options.disabled },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["text"].includes(_vm.item.type)
                          ? [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.item.options.defaultValue
                                  ),
                                },
                              }),
                            ]
                          : ["imgupload"].includes(_vm.item.type)
                          ? [
                              _c("v-uploader", {
                                attrs: {
                                  imgUrls: _vm.model,
                                  action: _vm.uploadURL,
                                  limit: _vm.item.options.length,
                                  fileWidth: _vm.item.options.size.width,
                                  disabled: _vm.item.options.disabled,
                                },
                                on: {
                                  "update:imgUrls": function ($event) {
                                    _vm.model = $event
                                  },
                                  "update:img-urls": function ($event) {
                                    _vm.model = $event
                                  },
                                },
                              }),
                            ]
                          : ["fileupload"].includes(_vm.item.type)
                          ? [
                              _c("v-uploader", {
                                attrs: {
                                  fileList: _vm.model,
                                  action: _vm.fileUploadURL,
                                  fileSize: 5,
                                  onlyImage: false,
                                  limit: _vm.item.options.limit,
                                  fileWidth: _vm.item.options.size.width,
                                  disabled: _vm.item.options.disabled,
                                },
                                on: {
                                  "update:fileList": function ($event) {
                                    _vm.model = $event
                                  },
                                  "update:file-list": function ($event) {
                                    _vm.model = $event
                                  },
                                },
                              }),
                            ]
                          : ["org"].includes(_vm.item.type)
                          ? [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: _vm.item.options.placeholder,
                                      disabled: _vm.item.options.disabled,
                                    },
                                    on: { onChange: _vm.orgChange },
                                    model: {
                                      value: _vm.model,
                                      callback: function ($$v) {
                                        _vm.model = $$v
                                      },
                                      expression: "model",
                                    },
                                  },
                                  "v-select2",
                                  _vm.orgParams,
                                  false
                                )
                              ),
                            ]
                          : ["community"].includes(_vm.item.type)
                          ? [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: _vm.item.options.placeholder,
                                      disabled: _vm.item.options.disabled,
                                    },
                                    on: { onChange: _vm.communityChange },
                                    model: {
                                      value: _vm.model,
                                      callback: function ($$v) {
                                        _vm.model = $$v
                                      },
                                      expression: "model",
                                    },
                                  },
                                  "v-select2",
                                  _vm.communityParams,
                                  false
                                )
                              ),
                            ]
                          : ["communityroom"].includes(_vm.item.type)
                          ? [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: _vm.item.options.placeholder,
                                      disabled: _vm.item.options.disabled,
                                    },
                                    on: { onChange: _vm.communityRoomChange },
                                    model: {
                                      value: _vm.model,
                                      callback: function ($$v) {
                                        _vm.model = $$v
                                      },
                                      expression: "model",
                                    },
                                  },
                                  "v-select2",
                                  _vm.communityroomParams,
                                  false
                                )
                              ),
                            ]
                          : ["area"].includes(_vm.item.type)
                          ? [
                              _c("area-select", {
                                attrs: {
                                  disabled: _vm.item.options.disabled,
                                  placeholder: _vm.item.options.placeholder,
                                  clearable: _vm.item.options.clearable,
                                  filterable: _vm.item.options.showinsearch,
                                  "detail-addr": false,
                                  type: _vm.item.options.type,
                                  codes: _vm.model,
                                },
                                on: {
                                  "update:codes": function ($event) {
                                    _vm.model = $event
                                  },
                                  change: _vm.selectedAreaChange,
                                },
                              }),
                            ]
                          : [
                              "username",
                              "userphone",
                              "useremail",
                              "userorg",
                              "userorgaddress",
                            ].includes(_vm.item.type)
                          ? [
                              _c("v-input", {
                                attrs: {
                                  placeholder: _vm.item.options.placeholder,
                                  maxlength: _vm.item.options.length,
                                },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["usersex"].includes(_vm.item.type)
                          ? [
                              _c("checkbox-plus", {
                                attrs: {
                                  type: "radio",
                                  value: _vm.model,
                                  options: _vm.item.options.options,
                                  align: _vm.item.options.inline
                                    ? "horizontal"
                                    : "vertical",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.model = $event
                                  },
                                },
                              }),
                            ]
                          : ["userage"].includes(_vm.item.type)
                          ? [
                              _c("v-input-number", {
                                attrs: { controls: "" },
                                model: {
                                  value: _vm.model,
                                  callback: function ($$v) {
                                    _vm.model = $$v
                                  },
                                  expression: "model",
                                },
                              }),
                            ]
                          : ["table"].includes(_vm.item.type)
                          ? [
                              _c("table-panel", {
                                attrs: {
                                  headers: _vm.tableHeaderMap(_vm.item),
                                  tableData: _vm.model,
                                  maxHeight: "500",
                                  operateColumnWidth: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "operateSlot",
                                      fn: function (scope) {
                                        return [
                                          _c("v-button", {
                                            attrs: {
                                              text: "编辑",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editRowMethod(
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                          _c("v-button", {
                                            attrs: {
                                              text: "删除",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteRowMethod(
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1069148997
                                ),
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { icon: "el-icon-plus", type: "text" },
                                  on: { click: _vm.addRowMethod },
                                },
                                [_vm._v("添加")]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }